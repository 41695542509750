<template>
  <div id="help">
    <a-tree :tree-data="treeData" :default-selected-keys="['Function']" @select="onSelect"/>

    <div class="doc-container">
      <div class="markdown-body">
        <component :is="mdComponents" ref="componentRef"/>
      </div>
    </div>
  </div>
</template>

<script>
import GnjsMd from "@/assets/help-md-en/Function introduction.md";
import GywmMd from "@/assets/help-md-en/About us.md";
import SjxzMd from "@/assets/help-md-en/Download.md";
import SjyclMd from "@/assets/help-md-en/Data preprocessing.md";
import YbxxsmMd from "@/assets/help-md-en/Sample information description.md";
import WorkflowMd from "@/assets/help-md-en/miRNA-Seq-workflow.md";
import PredictionMd from "@/assets/help-md-en/miRNA-target-prediction.md";
import "github-markdown-css";
import "highlight.js/styles/github.css";

export default {
  name: "LayoutHelp",
  data() {
    return {
      treeData: [
        {title: "Function introduction", key: "Function"},
        {title: "Data preprocessing", key: "preprocessing"},
        {title: "Sample information description", key: "information"},
        {title: "miRNA Target Gene Prediction", key: "Prediction"},
        {title: "miRNA-seq Analysis Workflow", key: "Workflow"},
        {title: "Download", key: "Download"},
        {title: "About us", key: "About"},
      ],
      mdComponents: GnjsMd
    }
  },
  methods: {
    onSelect(key) {
      switch (key[0]) {
        case "Function":
          this.mdComponents = GnjsMd;
          break;
        case "About":
          this.mdComponents = GywmMd;
          break;
        case "Download":
          this.mdComponents = SjxzMd;
          break;
        case "preprocessing":
          this.mdComponents = SjyclMd;
          break;
        case "information":
          this.mdComponents = YbxxsmMd;
          break;
        case "Prediction":
          this.mdComponents = PredictionMd;
          break;
        case "Workflow":
          this.mdComponents = WorkflowMd;
          break;
      }

      const mdDiv = document.querySelector(".markdown-body");
      // mdDiv 下面的所有链接加上download属性
      this.$nextTick(() => {
        const aList = mdDiv.querySelectorAll("a");
        console.log(aList);
        aList.forEach(item => {
          item.setAttribute("download", "");
          item.setAttribute("target", "_blank");
        });
      });

    }
  }
}
</script>

<style scoped lang="scss">
#help {
  background: #F9F9FB;
  padding: 1.4rem 8rem;
  //display: flex;
  //justify-content: space-between;
  display:grid;
  grid-template-columns: 1fr minmax(10px, 10fr);
  grid-gap: 20px;

  ::v-deep .ant-tree {
    background: #fff;
    //width: 13.35rem;
    width: max-content;
    height: max-content;

    li {
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      padding-right: 1rem;
      //cursor: pointer;

      .ant-tree-title {
        color: #666666;
        font-size: 18px;
        font-weight: 600;
        display: inline-block;
        white-space: pre-wrap;
        word-break: normal;
        word-wrap: break-word;
        overflow: hidden;
      }

      &:hover {
        background: #097F35;

        span {
          background: #097F35;
        }

        .ant-tree-title {
          color: #fff;
        }
      }

      .ant-tree-node-content-wrapper {
        width: 100%;
        transition: unset;
      }
    }

    .ant-tree-treenode-selected {
      background: #097F35;

      .ant-tree-node-selected {
        background: #097F35;

        .ant-tree-title {
          color: #fff;
        }
      }
    }
  }

  .doc-container {
    //width: 66rem;
    //margin-left: 0.65rem;
    background: #fff;
    padding: 3rem 2rem;

    .markdown-body {
      ::v-deep body {
        //h2{
        //    border-bottom:0;
        //    padding-bottom: 50px;
        //    font-size: 27px;
        //}

        //p{
        //    font-size: 18px;
        //    color: #333;
        //}
        //
        //h3{
        //    font-size: 21px;
        //}
      }
    }
  }
}
</style>